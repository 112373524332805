<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right">
            <button
              type="button"
              class="mb-2 mr-2 btn btn-primary"
              data-toggle="modal"
              data-target="#filterModal"
            >
              <i class="fa fa-filter"></i>
            </button>
          </div>
          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="mt-4 mb-4 table-responsive">
              <table
                id="DAILY_REMARKS"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Remark By</th>
                    <th>Type</th>
                    <th>Remark</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="dailyRemark in dailyRemarks" :key="dailyRemark.id">
                    <td>
                      {{
                        dailyRemark.user.firstname +
                        " " +
                        dailyRemark.user.lastname
                      }}
                    </td>
                    <td>
                      {{
                        dailyRemark.type.charAt(0).toUpperCase() +
                        dailyRemark.type.slice(1)
                      }}
                    </td>
                    <td>{{ dailyRemark.remark }}</td>
                    <td>{{ formatDateTime(dailyRemark.created_at) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Modal -->
    <div
      class="modal fade"
      id="filterModal"
      role="dialog"
      aria-labelledby="filterModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="filterModalLabel">Search Options</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="dateSearch">Filter</label>
                  <input
                    class="form-control"
                    type="date"
                    name="date"
                    id="date"
                    v-model="filter"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Discard
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="handleFilter()"
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import $ from "jquery";
import { endpoints } from "@/utils/endpoints";

export default {
  name: "DailyRemarks",

  data() {
    return {
      loading: false,
      dailyRemarks: [],
      filter: "",
    };
  },

  mounted() {
    if (this.$route.query) {
      this.filter = this.$route.query.date;
    }

    this.fetchRemarks();
  },

  methods: {
    initTable() {
      var dt = $("#DAILY_REMARKS").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        order: [[3, "desc"]],
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([4]).visible(false);
    },

    fetchRemarks() {
      this.loading = true;
      let url = endpoints.DAILY_REMARKS + `?date=${this.filter}`;

      http
        .get(url)
        .then((response) => {
          this.dailyRemarks = response.data;
          this.loading = false;

          setTimeout(() => {
            this.initTable();
          }, 1000);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    handleFilter() {
      this.fetchRemarks();
      $("#filterModal").modal("hide");
    },
  },
};
</script>

<style>
.select2-container {
  width: 60% !important;
}
.new-control {
  font-weight: 400;
  font-size: 15px;
}
</style>
